<template>
  <v-container id="user-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      <v-tabs color="#1b5e20">
        <v-tab>All Orders </v-tab>
        <v-tab>Profit Orders</v-tab>
        <v-tab-item>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="keyword"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  @input="doSearch"
                >
                </v-text-field>
              </v-card-title>
              <v-data-table
                ref="vDataTable"
                :headers="headers"
                :items="orders"
                hide-default-footer
                class="elevation-0"
              >
                <template #item="{ item, index, headers }">
                  <tr>
                    <td>
                      {{ index + 1 + (page - 1) * 10 }}
                      <v-badge inline color="orange" v-if="item.viewed == 0"> </v-badge>
                    </td>

                    <td class="hidden-sm-and-down">
                      <v-chip :color="getColor(item.order_type)" dark>
                        <v-text v-if="item.order_type == 1">OFFLINE</v-text>
                        <v-text v-else>ONLINE</v-text>
                      </v-chip>
                    </td>
                    <td>{{ item.tracking_number }}</td>
                    <td>
                      {{ item.invoice_number }}
                    </td>
                    <td class="hidden-sm-and-down">Rp. {{ item.total_bill }}</td>
                    <td class="hidden-sm-and-down">
                      {{ item.updated_at }}
                    </td>
                    <td class="hidden-sm-and-down">
                      {{ item.courier_service }}
                    </td>
                    <td>
                      {{ item.status }}
                    </td>
                    <td>
                      <v-text v-if="item.order_type == 1">
                        <v-icon color="red" class="mr-3"> mdi-store </v-icon>
                      </v-text>
                      <v-text v-else
                        ><v-icon color="green" class="mr-3" @click="editItem(item)">
                          mdi-truck-delivery-outline
                        </v-icon>

                        <v-icon color="green" class="mr-3" @click="printLabel(item)">
                          mdi-printer-outline
                        </v-icon>
                      </v-text>

                      <router-link
                        :to="{ name: 'report-order', params: { id: item.id } }"
                      >
                        <v-icon small class="mr-2"> mdi-magnify </v-icon>
                      </router-link>
                      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  @input="go"
                  :length="lengthPage"
                  :total-visible="5"
                  color="#1b5e20"
                >
                </v-pagination>
              </div>
        </v-tab-item>
        <v-tab-item>
          <ProfitOrder />
        </v-tab-item>
      </v-tabs>
    </base-material-card>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <div class="warning text-center">
            <header class="text-h5">Confirmation</header>
          </div>
          <v-card-title class="text-h6"
            >Are you sure you want to delete this Invoice:{{ editedItem.invoice_number }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#1b5e20"
              class="white--text"
              elevation="2"
              @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn
              color="#1b5e20"
              class="white--text"
              elevation="2"
              @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <div class="text-center">
            <header class="text-h5">View Order</header>
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text>Invoice Number</v-text>
                    <v-text-field v-model="editedItem.invoice_number" disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text>Shipping Service</v-text>
                    <v-text-field
                      v-model="editedItem.shipping_services"
                      required
                      :rules="shipping_servicesRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text>Tracking Number</v-text>
                    <v-text-field
                      v-model="editedItem.tracking_number"
                      required
                      :rules="tracking_numberRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text>Courier Service</v-text>
                    <v-text-field v-model="editedItem.courier_service" disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text>Status</v-text>
                    <v-text-field v-model="editedItem.status" disabled> </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#1b5e20"
                        class="white--text"
                        elevation="2" @click="close">
              Cancel
            </v-btn>
            <v-btn color="#1b5e20"
                        class="white--text"
                        elevation="2" @click="trackingOrder">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPrintLabel" max-width="500px">
        <base-material-card icon="mdi-printer" class="px-5 py-3">
        <body>
        <v-card>
          <div class="text-center">
            <header class="text-h5">PRINT LABEL</header>
          </div>
          <div id="InvPckPrint">
            <div class="page" size="A6" style="page-break-after: always">
              <v-card-text>
                <center id="top">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <tr>
                          <span>INVOICE NUMBER :</span>
                          <span>{{ editedItem.invoice_number }}</span>
                        </tr>
                      </v-col>
                      <br />
                      <v-col cols="6" sm="6" md="6">
                        <tr>
                          <span>FROM:</span>
                          <span>{{ setting.store }}</span
                          ><br />
                          <span>{{ setting.location_store }}</span
                          ><br />
                          <span>{{ setting.contact }}</span>
                        </tr>
                      </v-col>
                      <br />
                      <v-col cols="6" sm="6" md="6">
                        <tr>
                          <span>TO:</span>
                          <span>{{ user.name_order }}</span
                          ><br />
                          <span>{{ user.address }}</span
                          ><br />
                          <span>{{ user.phone }}</span>
                        </tr>
                      </v-col>
                    </v-row>
                  </v-container>
                </center>
              </v-card-text>
            </div>
          </div>
          <v-col cols="bg" class="text-center">
            <v-btn
              color="#1b5e20"
              class="white--text"
              @click="closePrintLabel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="#1b5e20" class="white--text"
              @click="printLabelInvoice"
            >
              <v-icon>mdi-printer </v-icon>Print
            </v-btn>
          </v-col>
        </v-card>
      </body>
      </base-material-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        ></v-progress-circular>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import ProfitOrder from "@/views/dashboard/pages/ProfitOrder.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ProfitOrder,
  },
  data() {
    return {
      orders: [],
      keyword: "",
      page: 0,
      lengthPage: 0,
      headers: [
        { text: "No", value: "id" },
        { text: "Type" },
        {
          text: "Tracking Number",
          value: "tracking_number",
          class: "hidden-sm-and-down",
        },
        { text: "Invoice", value: "invoice_number" },
        { text: "Tottal Price", value: "total_bill" },
        { text: "Date", value: "created_at" },
        { text: "Courier", value: "courier_service" },
        { text: "Status", value: "status" },
        { text: "Actions", sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      dialogPrintLabel: false,
      editedIndex: -1,
      editedItem: {
        id: "",
        tracking_number: "",
        shipping_services: "",
        status: "",
      },
      defaultItem: {
        id: "",
        tracking_number: "",
        shipping_services: "",
        status: "",
      },
      progressBar: false,
      id: "",
      tracking_numberRules: [(v) => !!v || "Tracking number is required"],
      shipping_servicesRules: [(v) => !!v || "Shipping service is required"],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      setting: "setting/setting",
    }),
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
      setSetting: "setting/set",
    }),
    getColor(order_type) {
      if (order_type == 1) return "red";
      else return "green";
    },
    go() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/orders?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.orders = data;
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/orders/search/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.orders = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
    deleteItem(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.progressBar = true;
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/orders/delete-permanent", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: "success",
          });
          this.orders.splice(this.editedIndex, 1);
          this.closeDelete();
          this.orders.push(this.editedItem);
          this.go();
          this.progressBar = false;
        })
        .catch((error) => {
          let { data } = error;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
          this.orders.push(this.editedItem);
          this.go();
          this.progressBar = false;
        });
    },
    trackingOrder() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("id", this.editedItem.id);
        formData.set("tracking_number", this.editedItem.tracking_number);
        formData.set("shipping_services", this.editedItem.shipping_services);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .post("/orders/tracking", formData, config)
          .then((response) => {
            let { data } = response;
            this.setAuth(data.data);
            this.setAlert({
              status: true,
              text: data.message,
              color: "success",
            });
            this.orders.splice(this.editedIndex, 1);
            this.orders.push(this.editedItem);
            this.close();
            this.go();
            this.progressBar = false;
          })
          .catch((error) => {
            let { data } = error;
            this.setAlert({
              status: true,
              text: data.message,
              color: "error",
            });
            this.orders.push(this.editedItem);
            this.close();
            this.go();
            this.progressBar = false;
          });
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closePrintLabel() {
      this.dialogPrintLabel = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    printLabel(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogPrintLabel = true;
    },
    printLabelInvoice() {
      let divToPrint = document.getElementById("InvPckPrint");
      let htmlToPrint =
        '<style type="text/css">' +
        "	* {	" +
        "	    border: 0;	" +
        "	    box-sizing: content-box;	" +
        "	    color: inherit;	" +
        "	    font-family: inherit;	" +
        "	    font-size: inherit;	" +
        "	    font-style: inherit;	" +
        "	    font-weight: inherit;	" +
        "	    line-height: inherit;	" +
        "	    list-style: none;	" +
        "	    margin: 0;	" +
        "	    padding: 0;	" +
        "	    text-decoration: none;	" +
        "	    vertical-align: top;	" +
        "	}	" +
        "	*[contenteditable] {	" +
        "	    border-radius: 0.25em;	" +
        "	    min-width: 1em;	" +
        "	    outline: 0;	" +
        "	}	" +
        "	*[contenteditable] {	" +
        "	    cursor: pointer;	" +
        "	}	" +
        "	*[contenteditable]:hover,	" +
        "	*[contenteditable]:focus,	" +
        "	td:hover *[contenteditable],	" +
        "	td:focus *[contenteditable],	" +
        "	img.hover {	" +
        "	    background: #DEF;	" +
        "	    box-shadow: 0 0 1em 0.5em #DEF;	" +
        "	}	" +
        "	span[contenteditable] {	" +
        "	    display: inline-block;	" +
        "	}	" +
        "	h1 {	" +
        "	    font: bold 100% sans-serif;	" +
        "	    letter-spacing: 0.5em;	" +
        "	    text-align: center;	" +
        "	    text-transform: uppercase;	" +
        "	}	" +
        "	table {	" +
        "	    font-size: 100%;	" +
        "	    table-layout: fixed;	" +
        "	    width: 100%;	" +
        "	}	" +
        "	table {	" +
        "	    border-collapse: separate;	" +
        "	    border-spacing: 2px;	" +
        "	}	" +
        "	th,	" +
        "	td {	" +
        "	    border-width: 1px;	" +
        "	    padding: 0.5em;	" +
        "	    position: relative;	" +
        "	    text-align: left;	" +
        "	}	" +
        "	th,	" +
        "	td {	" +
        "	    border-radius: 0.25em;	" +
        "	    border-style: solid;	" +
        "	}	" +
        "		" +
        "	th {	" +
        "	    background: #EEE;	" +
        "	    border-color: #BBB;	" +
        "	}	" +
        "		" +
        "	td {	" +
        "	    border-color: #DDD;	" +
        "	}	" +
        "	html {	" +
        '	    font: 16px/1 "Open Sans", sans-serif;	' +
        "	    overflow: auto;	" +
        "	    padding: 0.5in;	" +
        "	}	" +
        "		" +
        "	html {	" +
        "	    background: #999;	" +
        "	    cursor: default;	" +
        "	}	" +
        "		" +
        "	body {	" +
        "	    box-sizing: border-box;	" +
        "	    height: 100%;	" +
        "	    margin: 0 auto;	" +
        "	    overflow: hidden;	" +
        "	    padding: 0.5in;	" +
        "	    width: 100%;	" +
        "	}	" +
        "		" +
        "	body {	" +
        "	    background: #FFF;	" +
        "	    border-radius: 1px;	" +
        "	    box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);	" +
        "	}	" +
        "		" +
        "	/* header */	" +
        "		" +
        "	header {	" +
        "	    margin: 0 0 0;	" +
        "	}	" +
        "		" +
        "	header:after {	" +
        "	    clear: both;	" +
        '	    content: "";	' +
        "	    display: table;	" +
        "	}	" +
        "		" +
        "	header h1 {	" +
        "	    background: #000;	" +
        "	    border-radius: 0.25em;	" +
        "	    color: #FFF;	" +
        "	    margin: 0 0 1em;	" +
        "	    padding: 0.5em 0;	" +
        "	}	" +
        "		" +
        "	header address {	" +
        "	    float: left;	" +
        "	    font-size: 100%;	" +
        "	    font-style: normal;	" +
        "	    line-height: 1.25;	" +
        "	    margin: 0 1em 1em 0;	" +
        "	}	" +
        "		" +
        "	header address p {	" +
        "	    margin: 0 0 0.25em;	" +
        "	}	" +
        "		" +
        "	header span,	" +
        "	header img {	" +
        "	    display: block;	" +
        "	    float: right;	" +
        "	}	" +
        "		" +
        "	header span {	" +
        "	    margin: 0 0 1em 1em;	" +
        "	    max-height: 100%;	" +
        "	    max-width: 100%;	" +
        "	    position: relative;	" +
        "	}	" +
        "		" +
        "	header img {	" +
        "	    max-height: 100%;	" +
        "	    max-width: 100%;	" +
        "	}	" +
        "		" +
        "	header input {	" +
        "	    cursor: pointer;	" +
        '	    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";	' +
        "	    height: 100%;	" +
        "	    left: 0;	" +
        "	    opacity: 0;	" +
        "	    position: absolute;	" +
        "	    top: 0;	" +
        "	    width: 100%;	" +
        "	}	" +
        "		" +
        "	/* article */	" +
        "		" +
        "	article,	" +
        "	article address,	" +
        "	table.meta,	" +
        "	table.inventory {	" +
        "	    margin: 0 0 0em;	" +
        "	}	" +
        "		" +
        "	article:after {	" +
        "	    clear: both;	" +
        '	    content: "";	' +
        "	    display: table;	" +
        "	}	" +
        "		" +
        "	article h1 {	" +
        "	    clip: rect(0 0 0 0);	" +
        "	    position: absolute;	" +
        "	}	" +
        "		" +
        "	article address {	" +
        "	    float: left;	" +
        "	    font-size: 125%;	" +
        "	    font-weight: bold;	" +
        "	}	" +
        "		" +
        "	/* table meta & balance */	" +
        "		" +
        "	table.meta,	" +
        "	table.balance {	" +
        "	    float: right;	" +
        "	    width: 50%;	" +
        "	}	" +
        "		" +
        "	table.meta:after,	" +
        "	table.balance:after {	" +
        "	    clear: both;	" +
        '	    content: "";	' +
        "	    display: table;	" +
        "	}	" +
        "		" +
        "	/* table meta */	" +
        "		" +
        "	table.meta th {	" +
        "	    width: 40%;	" +
        "	}	" +
        "		" +
        "	table.meta td {	" +
        "	    width: 60%;	" +
        "	}	" +
        "		" +
        "	/* table items */	" +
        "		" +
        "	table.inventory {	" +
        "	    clear: both;	" +
        "	    width: 100%;	" +
        "	}	" +
        "		" +
        "	table.inventory th {	" +
        "	    font-weight: bold;	" +
        "	    text-align: center;	" +
        "	}	" +
        "		" +
        "	table.inventory td:nth-child(1) {	" +
        "	    width: 26%;	" +
        "	}	" +
        "		" +
        "	table.inventory td:nth-child(2) {	" +
        "	    width: 38%;	" +
        "	}	" +
        "		" +
        "	table.inventory td:nth-child(3) {	" +
        "	    text-align: right;	" +
        "	    width: 12%;	" +
        "	}	" +
        "		" +
        "	table.inventory td:nth-child(4) {	" +
        "	    text-align: right;	" +
        "	    width: 12%;	" +
        "	}	" +
        "		" +
        "	table.inventory td:nth-child(5) {	" +
        "	    text-align: right;	" +
        "	    width: 12%;	" +
        "	}	" +
        "	table.balance th,	" +
        "	table.balance td {	" +
        "	    width: 50%;	" +
        "	}	" +
        "	table.balance td {	" +
        "	    text-align: right;	" +
        "	}	" +
        "	aside h1 {	" +
        "	    border: none;	" +
        "	    border-width: 0 0 1px;	" +
        "	    margin: 0 0 1em;	" +
        "	}	" +
        "	aside h1 {	" +
        "	    border-color: #999;	" +
        "	    border-bottom-style: solid;	" +
        "	}	" +
        "	.add,	" +
        "	.cut {	" +
        "	    border-width: 1px;	" +
        "	    display: block;	" +
        "	    font-size: .8rem;	" +
        "	    padding: 0.25em 0.5em;	" +
        "	    float: left;	" +
        "	    text-align: center;	" +
        "	    width: 0.6em;	" +
        "	}	" +
        "	.add,	" +
        "	.cut {	" +
        "	    background: #9AF;	" +
        "	    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);	" +
        "	    background-image: -moz-linear-gradient(#00ADEE 5%, #0078A5 100%);	" +
        "	    background-image: -webkit-linear-gradient(#00ADEE 5%, #0078A5 100%);	" +
        "	    border-radius: 0.5em;	" +
        "	    border-color: #0076A3;	" +
        "	    color: #FFF;	" +
        "	    cursor: pointer;	" +
        "	    font-weight: bold;	" +
        "	    text-shadow: 0 -1px 2px rgba(0, 0, 0, 0.333);	" +
        "	}	" +
        "	.add {	" +
        "	    margin: -2.5em 0 0;	" +
        "	}	" +
        "	.add:hover {	" +
        "	    background: #00ADEE;	" +
        "	}	" +
        "	.cut {	" +
        "	    opacity: 0;	" +
        "	    position: absolute;	" +
        "	    top: 0;	" +
        "	    left: -1.5em;	" +
        "	}	" +
        "	.cut {	" +
        "	    -webkit-transition: opacity 100ms ease-in;	" +
        "	}	" +
        "	tr:hover .cut {	" +
        "	    opacity: 1;	" +
        "	}	" +
        "	@media print {	" +
        "	    * {	" +
        "	        -webkit-print-color-adjust: exact;	" +
        "	    }	" +
        "		" +
        "	    html {	" +
        "	        background: none;	" +
        "	        padding: 0;	" +
        "	    }	" +
        "		" +
        "	    body {	" +
        "	        box-shadow: none;	" +
        "	        margin: 0;	" +
        "	    }	" +
        "		" +
        "	    span:empty {	" +
        "	        display: none;	" +
        "	    }	" +
        "		" +
        "	    .add,	" +
        "	    .cut {	" +
        "	        display: none;	" +
        "	    }	" +
        "	}	" +
        "	@page {	" +
        "	    margin: 0;	" +
        "	}	" +
        "</style>";
      htmlToPrint += divToPrint.outerHTML;
      let windowToPrint = window.open("");
      windowToPrint.document.write(htmlToPrint);
      windowToPrint.print();
      windowToPrint.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPrintLabel(val) {
      val || this.close();
    },
  },
  created() {
    this.go();
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
